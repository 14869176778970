import React from 'react'
import Layout from '../../layouts/layout'

class Legal extends React.Component {
  render () {
    return (
      <Layout>
        {/* Page Header */}
        <header className="masthead legal">
          <div className="container">
            <div className="row">
              <div className="col-lg-8 col-md-10 mx-auto">
                <div className="site-heading">
                  <h1>Credits & Legal Stuff</h1>
                </div>
              </div>
            </div>
          </div>
        </header>
        {/* Legal Content */}
          <div className="container">
            <div className="row">
              <div className="col-lg-8 col-md-10 mx-auto">
                <h2>CREDITS:</h2>
                <p>Many thanks to the wonderful folks at <a href="https://getbootstrap.com/">Bootstrap</a>, those at Facebook working on <a href="https://reactjs.org/">React</a>, and everyone working on <a href="https://www.gatsbyjs.com/">GatsbyJS</a> library. Combined, these tools have made this blog a pleasure to develop, maintain, and bring to the forefront of search engine optimization techniques.</p>
                <p>This site's favicon is a heavily modified version of IconArchive's <a href="http://www.iconarchive.com/show/colorful-long-shadow-icons-by-graphicloads/Cup-champion-icon.html">cup champion icon.</a></p>
                <p>Last but not least, listed simply in alphabetical order, all the other wonderful open source tools this site relies on:</p>
                <ul>
                  <li>axios@0.17.0</li>
                  <li>bootstrap@4.0.0-beta</li>
                  <li>codemirror@5.31.0</li>
                  <li>email-validator@1.1.1</li>
                  <li>highlight.js@9.12.0</li>
                  <li>jquery@3.2.1</li>
                  <li>pg@7.3.0</li>
                  <li>popper.js@1.12.5</li>
                  <li>react-copy-to-clipboard@5.0.1</li>
                  <li>react-highlight@0.10.0</li>
                  <li>react-notify-toast@0.4.0</li>
                  <li>react-syntax-highlight@15.3.1</li>
                </ul>
                <h2>NLP CHAMPS LICENSE:</h2>
                  <p>The posts on NLP Champs can be used without restriction, including without limitation the rights to use, copy, modify, merge, publish, distribute, sublicense, and even sell copies of what you find here.</p>
                  <p>With that said, we hope you give a small original credit or mention to NLP Champs when you do so.</p>
                  <h2>PRIVACY POLICY:</h2>
                  <p>NLP-Champs.com does not collect any personal information about you as a visitor except standard traffic logs automatically generated by our web server and Google Analytics. And anything you leave deliberately as part of a comment / post as a guest poster of course. We will never sell or disclose your personal information, including your email address.</p>
                  <h2>AFFILATE RELATIONSHIPS:</h2>
                  <p>NLP-Champs.com will (hopefully) begin generating income through affiliate relationships with some of the companies mentioned within. These are generally companies which we use or have used ourselves. NLP-Champs.com does not accept any paid content and endorsement articles are not available at any price. Otherwise, we have no ads :)</p>
                  <h2>CODE OF CONDUCT:</h2>
                  <p>NLP Champs is a public community. Everyone is welcome to participate in NLP deliciousness regardless of race, religion, gender, sexual orientation, appearance, or anything else. Not only is everyone welcome, we want everyone to feel comfortable and unafraid to contribute.
                  To make that happen, we present these rules of conduct that we promise to enforce. In any of our posts or comments, the following are unacceptable:</p>
                  <ul>
                    <li>Pornographic / Sexual</li>
                    <li>Bigoted</li>
                    <li>Sexist</li>
                    <li>Racist</li>
                    <li>Rude</li>
                    <li>Attacking</li>
                    <li>Intimidating</li>
                    <li>Harassing</li>
                    <li>Stalking</li>
                    <li>Disruptive</li>
                    <li>Offensive</li>
                    <li>Spam</li>
                  </ul>

                  <p>This isn't a complete list. It's so you understand what we DON'T want to see in our blog. If the NLP Champs Team finds that any comment includes any of this nonsense, we reserve the exclusive right to remove those comments.</p>

                  <p>One other note: we're all hear to learn! (Even us, the NLP Champs Team - we don't know everything there is to know about NLP and we expect you don't either - if you do, you can write a post to end all posts for us :) ) With that said, we will also have short temper for comments which ever contain more destructive than constructive criticism. Ex. Commenting simply "That is stupid, I wouldn't do it that way." is totally unconstructive. What would be acceptable is "That's a pretty inefficient method of doing this, I would do XXX, YYY, and ZZZ." - in this way everyone can learn something and a constructive comment like this opens up another discussion point.</p>

                  <p>So, in short, we're not against strong criticism or even a <i>high</i> level of criticism, we're against destructive/unproductive criticsm.</p>

                  <p>Thanks everybody for mulling over these legal things.</p>

                  <p>- Steve, Chris, and the rest of NLP Champs Team</p>
           </div>
           </div>
         </div>
      </Layout>
    );
  }
}

export default Legal;
